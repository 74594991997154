/**
* Created by litian on 2019-04-01.
*/
<style lang="less">
.fxb-60 {
    flex: 0 0 60px;
}

.t-r {
    text-align: right;
}
</style>
<template>
    <div class="page-content monitor" style="height: 100%;">
        <div id="container" class="map-box"></div>
    </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import mapMixins from '../../components/mixins/map.vue';
import deviceMix from '../../components/mixins/device-mix';
import { getAddressNode, getLocation } from "../../libs/deviceUtil";
export default {
    mixins: [mapMixins, deviceMix],
    data() {
        return {
            loading: false,
            device: '',
            deviceList: [],
            ...this.$route.query
        }
    },
    methods: {
        fetchDevices() {

            this.$ajax.post('/device/list', {
                page: 1,
                pageSize: 100,
                status: 1
            }, res => {
                if (res.code === 200) {

                    let deviceIds = res.data.data;
                    if (deviceIds.length) {
                        this.$ajax.post('/device/fetch_device_info_list', {
                            deviceList: _.map(deviceIds, 'device'),
                        }, r => {
                            if (r.code === 200) {

                                this.deviceList = r.data
                                if (this.deviceList.length) {
                                    this.updateMap()
                                    this.loading = false;
                                } else {
                                    this.$Message.error('暂无数据');
                                }
                            }
                        }, err => {
                            this.$Message.error(err.message || '服务器异常，请重试');
                        });
                    }

                    // this.total = res.data.total;
                    // this.isAll = false;
                }
                // //客户维度搜索
                // if (this.queryData.uid) {
                //     delete this.queryData.uid;
                // }
                // //订单维度搜索
                // if (this.queryData.oid) {
                //     delete this.queryData.oid;
                // }
                // if (res.code === 200) {

                //     this.tableData = res.data.data;
                //     this.total = res.data.total;
                //     this.isAll = false;
                // }
                // this.loading = false;
            }, err => {
                this.loading = false;
                this.$Message.error(err.message || '服务器异常，请重试');
            });



            //显示单个设备
            // if (this.device) {
            //     this.$ajax.post('device/fetch_device_info_list', {
            //         deviceList: this.deviceList,
            //     }, res => {
            //         //console.log(res);
            //         if (res && res.device) {
            //             this.setDevice(res);
            //         } else {
            //             this.$Message.error('暂无数据');
            //         }
            //     }, err => {
            //         this.$Message.error(err.message || '服务器异常，请重试');
            //     });
            // }
        },
        setDevice(device) {
            let index = _.findIndex(this.deviceList, item => {
                return item.device === device.device;
            });
            if (index > -1) {
                this.deviceList.splice(index, 1, device); //替换
            } else {
                this.deviceList.push(device);
            }
            this.updateMap();
        },
        updateMap() {
            let makerList = this.createMakerList();
            makerList = makerList.filter(maker => {
                return maker !== null;
            });
            this.map.clearMap();
            this.map.add(makerList);
            this.map.setFitView();
        },
        createMakerList() {
            let { SimpleInfoWindow, map } = this;

            return this.deviceList.map((device, i) => {
                //console.log(track,this.iconMap[track.locateType]);
                if (device.lng && device.lat) {
                    let icon = this.statusIcon[device.status];
                    let marker = new AMap.Marker({
                        position: [device.lng, device.lat], //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                        title: `设备${device.device}`,
                        icon: icon,
                        anchor: 'bottom-center',
                        label: {
                            content: `<div class="maker-title">${device.carNumber}</div>`,
                            offset: new AMap.Pixel(-26, -29)
                        }
                    });
                    let dateStr = moment(device.locateTime * 1000).format('YYYY-MM-DD HH:mm:ss');
                    window.handleSetConfig = () => {
                        this.startReportedRate(device);
                    };

                    window.handleShowDeviceDetail = () => {
                        this.showDeviceDetail(device);
                    };
                    let trackAddressNode = getAddressNode(device);
                    //device.chargerState !== undefined
                    let detailInfo = new SimpleInfoWindow({
                        infoTitle: `<strong>${device.device} | ${device.modeName || ''}</strong>`,
                        infoBody: `<div class="info-window-ul">
                                <div class="flex flex-top flex-left">
                                    <div style="flex:0 0 60px;text-align: right;">当前电量：</div><div class="flex-item">${device.powerRate || 0}% ${ device.chargerState === 1 ? '<div style="color: green;display: inline;font-size:10px;">充电中</div>' : ''}</div>
                                </div>
                                <div class="flex flex-top flex-left">
                                    <div style="flex:0 0 60px;text-align: right;">速度：</div><div class="flex-item">${device.speed || 0} km/h</div>
                                </div>
                                ${
                                    device.temperature ? `<div class="flex flex-top flex-left">
                                        <div class="fxb-60 t-r">温度：</div><div class="flex-item">${device.temperature || ''} ℃</div>
                                    </div>` : ''
                                }
                                ${
                                    device.humidity ? `<div class="flex flex-top flex-left">
                                        <div class="fxb-60 t-r">湿度：</div><div class="flex-item">${device.humidity || ''} %RH</div>
                                    </div>` : ''
                                }
                                <div class="flex flex-top flex-left">
                                    <div style="flex:0 0 60px;text-align: right;">定位类型：</div><div class="flex-item">${device.locateTypeName || ''}</div>
                                </div>
                                <div class="flex flex-top flex-left">
                                    <div style="flex:0 0 60px;text-align: right;">定位时间：</div><div class="flex-item">${dateStr}</div>
                                </div>
                                <div class="flex flex-top flex-left">
                                    <div style="flex:0 0 60px;text-align: right;">定位地址：</div><div class="flex-item" id="addr-${device.locateTime}">${trackAddressNode}</div>
                                </div>
                                <div class="flex" style="text-align: center;">
                                    <a class="flex-item" href="javascript:;" onclick="handleSetConfig()">设置</a>
                                    <a class="flex-item" href="javascript:;" onclick="handleShowDeviceDetail()">详情</a>
                                    <a class="flex-item" href="/devices/trail_back?device=${device.device}" target="_blank">轨迹</a>
                                </div>
                            </div>`,
                        offset: new AMap.Pixel(-10, -54)
                    });

                    marker.on('click', function() {
                        detailInfo.open(map, marker.getPosition());
                    });

                    if (this.device) {
                        setTimeout(() => {
                            detailInfo.open(map, marker.getPosition());
                        }, 500);
                    }

                    return marker;
                } else {
                    return null;
                }
            })
        },
        loadMapUI(cb) {
            AMapUI.loadUI(['overlay/SimpleInfoWindow', 'overlay/SimpleMarker'], (SimpleInfoWindow, SimpleMarker) => {
                this.SimpleInfoWindow = SimpleInfoWindow;
                this.SimpleMarker = SimpleMarker;
                cb();
            })
        },
        findDeviceByNumber(deviceNumber) {
            let { deviceList } = this;
            if (deviceList) {
                return _.find(deviceList, device => {
                    return device.device === deviceNumber
                })
            } else {
                return null;
            }
        }
    },
    mounted() {
        this.loadMapUI(() => {
            this.fetchDevices();
        });
        let vm = this;
        window.getDeviceLocation = function(deviceNumber, locateTime) {
            let pointData = vm.findDeviceByNumber(deviceNumber);
            if (pointData) {
                getLocation(pointData, (err, point) => {
                    if (err) {
                        vm.$Message.error('服务错误，请重试');
                    } else {
                        vm.updateMap()
                    }
                })
            } else {
                vm.$Message.error('服务错误，请重试');
            }
        }
    }
};
</script>